import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { shuffle } from 'lodash'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class DiscoveryAreaController {
  @observable topPosts: PostStore[] = []
  @observable topPostsDisplayed: PostStore[] = []
  @observable userToFollows: ProfileModel[] = []
  @observable userToFollowsDisplayed: ProfileModel[] = []
  @observable daoToJoins: any[] = []
  @observable daoToJoinsDisplayed: any[] = []
  @observable reviewToRead: PostStore[] = []
  @observable reviewToReadDisplayed: PostStore[] = []
  @observable fetching = false
  @observable seeMore = false

  constructor() {
    this.fetchTopPosts()
    this.fetchUserToFollow()
    this.fetchDaoToJoin()
    this.fetchReviewToRead()
  }

  @asyncAction *fetchTopPosts() {
    try {
      this.fetching = true
      //TODO: Handle type everyone
      const topPosts = yield apiService.posts.fetchPosts({ _sort: 'topScore:desc', _limit: 21, type: 'everyone' })
      this.topPosts = topPosts.map((item) => new PostStore(item))
      this.topPostsDisplayed = this.topPosts.slice(0, 3)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @asyncAction *fetchUserToFollow() {
    try {
      const res = yield apiService.profiles.getUsersToFollow({page: 0, pageSize: 10})
      if(res) {
        this.userToFollows = res.data
        this.userToFollowsDisplayed = this.userToFollows.slice(0, 3)
      }
    } catch (error) {}
  }

  @asyncAction *fetchDaoToJoin() {
    try {
      const daos = yield apiService.daos.getMostActiveDaos()
      this.daoToJoins = daos
      this.daoToJoinsDisplayed = this.daoToJoins.slice(0, 4)
    } catch (error) {}
  }

  @asyncAction *fetchReviewToRead() {
    try {
      const reviews = yield apiService.posts.fetchPosts({ _sort: 'topScore:desc', _limit: 20, type: 'review' })
      this.reviewToRead = reviews.map((review) => new PostStore(review))
      this.reviewToReadDisplayed = this.reviewToRead.slice(0, 2)
    } catch (error) {
      snackController.commonError(error)
    }
  }

  randomElements(arrays, itemNumber) {
    return shuffle(arrays).slice(0, itemNumber)
  }

  @action.bound updateTopPostsDisplayed() {
    this.topPostsDisplayed = this.randomElements(this.topPosts, 3)
  }

  @action.bound updateUserToFollowsDisplayed() {
    this.userToFollowsDisplayed = this.randomElements(this.userToFollows, 3)
  }

  @action.bound updateDaoToJoinsDisplayed() {
    this.daoToJoinsDisplayed = this.randomElements(this.daoToJoins, 4)
  }

  @action.bound updateReviewToReadDisplayed() {
    this.reviewToReadDisplayed = this.randomElements(this.reviewToRead, 2)
    this.seeMore = false
  }

  @action.bound seeMoreReview() {
    if (!this.seeMore) {
      this.reviewToReadDisplayed = this.reviewToRead
      this.seeMore = true
    }
  }
}

export const discoveryAreaController = new DiscoveryAreaController()

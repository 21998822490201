




































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { discoveryAreaController } from './discovery-area-controller'

@Observer
@Component({
  components: {
    'user-list': () => import('@/modules/community/home/components/user-list.vue'),
    'user-item': () => import('@/modules/community/home/components/user-item.vue'),
    'community-list': () => import('./community-list.vue'),
    'trending-card': () => import('./review-components/trending-card.vue'),
  },
})
export default class extends Vue {
  discoveryAreaController = discoveryAreaController

  tabIndex = 0

  get tabs() {
    return this.$vuetify.breakpoint.width >= 1280
      ? ['Top Post', 'User to follow', 'DAO to join']
      : ['Top', 'User', 'DAO']
  }

  get tab() {
    return this.tabs[this.tabIndex]
  }

  onChange(index: any) {
    if (this.tabIndex != index) this.tabIndex = index
  }

  updateList() {
    if (this.tab === this.tabs[0]) {
      this.discoveryAreaController.updateTopPostsDisplayed()
    } else if (this.tab === this.tabs[1]) {
      this.discoveryAreaController.updateUserToFollowsDisplayed()
    } else if (this.tab === this.tabs[2]) {
      this.discoveryAreaController.updateDaoToJoinsDisplayed()
    }
  }
}
